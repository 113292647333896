import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import ArrowBackIcon from "@material-ui/icons/ArrowBack"

export default function Card(props) {
  return (
    <div className="group p-8 lg:py-10vw w-full h-full flex flex-col justify-center sm:justify-start items-center lg:bg-black lg:bg-opacity-50 filter duration-300 hover:bg-opacity-0">
      <h2 className="text-white mb-8 px-4 sm:h-1/6 md:h-auto lg:w-auto lg:h-16 lg:mb-10vh">
        {props.title}
      </h2>
      <ul className="grid grid-cols-2 md:grid-cols-1 gap-4 md:gap-0 lg:opacity-0 duration-300 group-hover:opacity-100">
        {props.items.map((item, index) => (
          <li key={index}>
            <AnchorLink
              to={props.link}
              className="block transform text-white opacity-60 hover:opacity-100 hover:-translate-y-1 duration-300 text-center font-medium px-2 py-4 md:py-2 lg:py-4"
            >
              {item}
            </AnchorLink>
          </li>
        ))}
      </ul>
      <AnchorLink to={props.link} className="mt-4 sm:mt-auto">
        <div className="block p-4 text-2xl text-white transform hover:-translate-y-1 rotate-180 opacity-60 hover:opacity-100 duration-300">
          <ArrowBackIcon fontSize="inherit" color="inherit" />
        </div>
      </AnchorLink>
    </div>
  )
}
