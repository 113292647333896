import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Seo from "../components/Seo"
import ContactForm from "../components/ContactForm"
import Card from "../components/Card"
import Hero from "../components/Hero"
import Layout from "../components/Layout"

import heroMp4 from "../videos/socialmindr-bg-video.mp4"
import heroWebm from "../videos/socialmindr-bg-video.webm"

export default function Home() {
  return (
    <Layout calendly>
      <Seo />
      <Hero
        video
        videoMp4={heroMp4}
        videoWebm={heroWebm}
        title="Built for the Future"
        banner
      >
        <div className="bg-primary h-1 w-14 my-5 sm:mb-2 sm:mt-3 md:my-8"></div>
        <div className="text-white text-sm md:text-base mb-7 sm:mb-3 md:mb-7 sm:w-11/12 md:w-auto lg:leading-relaxed">
          <p>SocialMindr is Australia's most popular lead-generation agency.</p>
          <p>
            Harness the power of technology and take control of your digital
            footprint.
          </p>
        </div>
        <AniLink to="/contact-us" cover direction="up" bg="#ffffff">
          <button className="py-2 px-4 md:py-4 md:px-8 text-sm md:text-base uppercase font-medium bg-primary rounded-md hover:bg-primary-lighter duration-300">
            Book a free consultation
          </button>
        </AniLink>
      </Hero>
      <section className="section lg:w-full mx-auto">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 lg:place-items-center">
          <div className="text-center sm:text-left lg:w-9/12">
            <h2 className="mb-8 w-11/12 md:w-7/12 lg:w-auto sm:text-left md:text-5xl md:leading-tight">
              A world leader in marketing technology
            </h2>
            <p className="text-sm text-dark-lighter opacity-60 mb-4 lg:text-lg">
              Proprietary technology is the cornerstone to SocialMindr’s
              success. Maximise your ROI and gain full transparency of your
              marketing strategy. Achieve results on day one.
            </p>
          </div>
          <StaticImage
            src="../images/socialmindr-tech.png"
            alt="A laptop with the SocialMindr marketing dashboard."
            className="h-halfscreen"
            objectFit="contain"
          />
        </div>
      </section>
      <section className="section relative lg:p-0 lg:h-screen lg:overflow-hidden">
        <div className="absolute inset-0 -z-10 bg-dark-lighter">
          <StaticImage
            src="../images/services-bg.jpg"
            alt="An overgrown castle contained in the screen of a phone."
            className="hidden sm:block h-full filter brightness-25 lg:brightness-50"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:h-full lg:w-full">
          <Card
            title="social media advertising"
            items={[
              "LinkedIn",
              "Instagram",
              "Instagram",
              "Reddit",
              "Spotify",
              "Google AdWords",
            ]}
            link="/what-we-do#social-media-advertising"
          ></Card>
          <Card
            title="social media automation"
            items={[
              "LinkedIn Bot",
              "Instagram Bot",
              "Facebook Bot",
              "Data Scraping",
            ]}
            link="/what-we-do#social-media-automation"
          ></Card>
          <Card
            title="creative hub"
            items={[
              "UI/UX",
              "Graphic Design",
              "Photography",
              "Copywriting",
              "Videography",
            ]}
            link="/what-we-do#creative-hub"
          ></Card>
          <Card
            title="software development"
            items={[
              "iOS and Android Apps",
              "Chatbot Development",
              "Web Development",
            ]}
            link="/what-we-do#software-development"
          ></Card>
        </div>
      </section>
      <section className="section bg-primary">
        <h2 className="text-white md:text-5xl md:leading-tight">
          Every solution is tailored to you
        </h2>
        <p className="text-white opacity-60 my-6 lg:my-10">
          We've created unique strategies for:
        </p>
        <ul className="grid grid-cols-3 sm:grid-cols-5 md:grid-cols-3 lg:grid-cols-5 gap-4 md:gap-8 place-content-center place-items-center">
          <li className="client-logo h-8">
            <StaticImage
              src="../images/client-logos/logo-zip.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Zip logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/logo-sharp.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Sharp logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/logo-ray-white.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Ray White logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/logo-cimenviro.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Cim Enviro logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/growth-tank.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Growth Tank logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/dawn.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Dawn logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/classbento.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Class Bento logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/jumbla.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Jumbla logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/startcon.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Startcon logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/rise.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Rise logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/belle-property.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Belle Property logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/Firefly.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Firefly logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/logo-freelancer.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Freelancer logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/thiga.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Thiga logo"
            />
          </li>
          <li className="client-logo">
            <StaticImage
              src="../images/client-logos/foodbomb.png"
              placeholder="tracedSVG"
              objectFit="contain"
              alt="Foodbomb logo"
            />
          </li>
        </ul>
      </section>
      <section className="section">
        <ContactForm></ContactForm>
      </section>
    </Layout>
  )
}
